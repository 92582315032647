<template>
  <div class="shell-box">
    <el-breadcrumb separator="/"
      class="breadcrumb">
      <el-breadcrumb-item :to="{ path: '/myHome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/routerClaimManagement/nonClaimManagement' }">理赔列表</el-breadcrumb-item>
      <el-breadcrumb-item>添加报案</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="form">
      <policyForm ref="policyForm"
        :detailData="policyDetail"
        :policySubjectList="policySubjectList"
        :policyInsuredList="policyInsuredList"
        :policyDisabled="$route.query.policyId ? true : false"
        :permissions="policyDetailPermissions" />
    </div>
    <div class="operating-box">
      <el-button size="mini"
        @click="handleBack">返回</el-button>
      <el-button size="mini"
        type="primary"
        @click="handleReset"
        plain>重置</el-button>
      <el-button size="mini"
        type="primary"
        v-if="$route.query.claimId"
        @click="handleSaveDraft(2)">保存</el-button>
      <el-button size="mini"
        type="primary"
        v-else
        @click="handleSaveDraft(1)">存入草稿箱</el-button>
      <el-button size="mini"
        type="warning"
        plain
        @click="handlePolicyUpdate(1)">提交并返回列表</el-button>
      <el-button size="mini"
        type="primary"
        @click="handlePolicyUpdate(2)">提交并进入验标</el-button>
    </div>
  </div>
</template>

<script>
import policyForm from "@/views/claimManagement/nonComponents/applyClaimForm.vue";
import { getPolicyDetail } from "@/api/policy";
import { insNonClaimsCaseAdd, insNonClaimsCaseDraftAdd, insNonClaimsCaseDraftDetail, insNonClaimsCaseDraftUpdate, insNonClaimsCaseDraftSubmit } from "@/api/claimData";
import { hasPerms, dataPermissions } from "@/utils/auth";
export default {
  components: { policyForm },
  data() {
    return {
      policyDetail: null,
      originalPolicyDetail: {},
      policySubjectList: [],
      policyInsuredList: [],

      policyDetailPermissions: {
        // 车架号
        frameNo: true,
        // 案件跟进人
        caseFollower: true,
        // 现场联系人手机号
        siteContactPhone: true,
        // 出险类型适用条款
        dangerTypeClause: true,
        // 业务员
        policySalesman: true,
        // 报案人姓名
        reportCasePersonName: true,
        // 预估损失金额
        estimateLoss: true,
        // 保司报案号
        realCaseNo: true,
        // 保单号
        policyNo: true,
        // 报案人联系电话
        reportCasePhone: true,
        // 设备型号
        equipmentType: true,
        // 现场联系人姓名
        siteContactName: true,
        // 保险公司
        policyCompany: true,
        // 第二被保险人
        policySecondInsured: true,
        // 出厂日期
        productionDate: true,
        // 投保省份
        policyProvince: true,
        // 第一被保险人
        policyFirstInsured: true,
        // 报案时间
        reportCaseTime: true,
        // 设备品牌
        brand: true,
        // 设备类型
        deviceType: true,
        // 事故原因
        accidentCause: true,
        // 案件责任比例
        dutyRate: true,
        // 设备受损部位
        damagedParts: true,
        // 案件编号
        caseNo: true,
        // 小时数
        workTime: true,
        // 投保方式
        policyApplicantMode: true,
        // 车架号后6位
        vinLast: true,
        // 出险时间
        dangerDate: true,
        // 出险信息备注
        dangerRemark: true,
        // 险种名称
        policyInsuranceType: true,
        // 整备质量
        curbWeight: true,
        // 投保门店
        policyApplicantStore: true,
        // 理算专员
        adjustmentAssistant: true,
        // 区域经理
        regionalManager: true,
        // 出险类型
        dangerTypeId: true,
        // 出险详细地址
        dangerDetailAddress: true,
        // 所属项目
        projectName: true,
      }
    };
  },
  created() {
    this.$route.query.policyId ? this.getDetail(this.$route.query.policyId) : this.$route.query.claimId ? this.getDraftDetail(this.$route.query.claimId) : '';
  },
  activated() {
    this.$route.query.policyId ? this.getDetail(this.$route.query.policyId) : this.$route.query.claimId ? this.getDraftDetail(this.$route.query.claimId) : '';
  },
  methods: {
    getDetail(id) {
      getPolicyDetail({
        id
      }).then(res => {
        this.policyDetail = {
          "policyApplicantMode": res.data.applicantModeName,
          "policyApplicantStore": res.data.applicantStoreName,
          "policyCompany": res.data.acceptCompanyList && res.data.acceptCompanyList.length ? res.data.acceptCompanyList[0].companyName : '',
          "policyEndDate": res.data.endDate,
          "policyFirstInsured": res.data.policyInsuredList && res.data.policyInsuredList.length ? res.data.policyInsuredList[0].playersName : '',
          "policyId": res.data.id,
          "policyInsuranceType": res.data.insuranceTypeName || '',
          "policyNo": res.data.policyNo,
          "policyProvince": res.data.province,
          "policySalesman": res.data.salesmanName,
          "policySecondInsured": res.data.policyInsuredList && res.data.policyInsuredList.length > 1 ? res.data.policyInsuredList[1].playersName : '',
          "policyStartDate": res.data.startDate,
          "bindUserName": res.data.bindUserName,
          "bindUserId": res.data.bindUserId,
          "projectName": res.data.projectName,
        };
        this.policySubjectList = res.data.policySubjectList;
        res.data.policyInsuredList && res.data.policyInsuredList.length > 1 ? res.data.policyInsuredList.splice(0, 1) : '';
        console.log(res.data.policyInsuredList);
        this.policyInsuredList = res.data.policyInsuredList || [];
        this.originalPolicyDetail = JSON.parse(JSON.stringify(this.policyDetail));
      }).catch(err => {
        console.log(err);
      });
    },
    getDraftDetail(id) {
      insNonClaimsCaseDraftDetail({
        id
      }).then(res => {
        let policyDetail = res.data;
        res.data.policyId ? getPolicyDetail({
          id: res.data.policyId
        }).then(policyRes => {
          this.policySubjectList = policyRes.data.policySubjectList;
          policyDetail.bindUserName = policyRes.data.bindUserName;
          policyDetail.bindUserId = policyRes.data.bindUserId;
          this.policyDetail = policyDetail;
          policyRes.data.policyInsuredList && policyRes.data.policyInsuredList.length > 1 ? policyRes.data.policyInsuredList.splice(0, 1) : '';
          this.policyInsuredList = policyRes.data.policyInsuredList || [];
        }).catch(err => {
          console.log(err);
        }) : this.policyDetail = policyDetail;
        this.originalPolicyDetail = JSON.parse(JSON.stringify(this.policyDetail));
        this.$forceUpdate();
      }).catch(err => {
        console.log(err);
      });
    },
    handleBack() {
      this.$router.go(-1);
    },
    async handlePolicyUpdate(type) {
      let data = await this.$refs.policyForm.handleSubmit();
      let claimType = {}
         claimType.orderType = 21;
         claimType.category = 1;
      if (this.$route.query.claimId) {
        insNonClaimsCaseDraftSubmit({ ...data, gotoYB: type == 2 ? true : false }).then(res => {
          if (res) {
            if (type == 1) {
              this.$router.push({ path: '/routerClaimManagement/claimManagement' });
            } else {
              this.$router.push({ path: '/routerClaimManagement/claimDetails/' + res.data.id });
            }
            this.$message.success('添加成功');
          }
        }).catch(err => {
          console.log(err);
        });
        return false;
      }
      insNonClaimsCaseAdd({ ...data, ...claimType, gotoYB: type == 2 ? true : false }).then(res => {
        if (res) {
          if (type == 1) {
            this.$router.push({ path: '/routerClaimManagement/claimManagement' });
          } else {
            this.$router.push({ path: '/routerClaimManagement/claimDetails/' + res.data.id });
          }
          this.$message.success('添加成功');
        }
      }).catch(err => {
        console.log(err);
      });
    },
    async handleSaveDraft(type) {
      let data = await this.$refs.policyForm.handleSubmit('draft');
      let claimType = {}
      claimType.orderType = 21;
      claimType.category = 1;
      if (type == 1) {
        insNonClaimsCaseDraftAdd({ ...data, ...claimType, gotoYB: false }).then(res => {
          if (res) {
            //  if (type == 1) {
            // this.$router.push({ path: '/routerClaimManagement/claimManagement' });
            this.$router.go(-1);
            //  } else {
            // this.$router.push({ path: '/routerClaimManagement/claimDetails/' + res.data.id });
            //  }
            this.$message.success('添加成功');
          }
        }).catch(err => {
          console.log(err);
        });
      } else {
        insNonClaimsCaseDraftUpdate({ ...data, gotoYB: false }).then(res => {
          if (res) {
            //  if (type == 1) {
            // this.$router.push({ path: '/routerClaimManagement/claimManagement' });
            this.$router.go(-1);
            //  } else {
            // this.$router.push({ path: '/routerClaimManagement/claimDetails/' + res.data.id });
            //  }
            this.$message.success('编辑成功');
          }
        }).catch(err => {
          console.log(err);
        });
      }
    },
    handleReset() {
      this.$refs.policyForm.handleReset();
      this.$forceUpdate();
    },
  }
}
</script>

<style lang="scss" scoped>
.shell-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  .form {
    flex: 1;
    overflow: hidden;
  }
  .breadcrumb {
    padding: 10px 0;
    ::v-deep .el-breadcrumb__inner.is-link {
      color: rgba(0, 128, 255, 1);
    }
  }
  .operating-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 50px;
    padding: 0 24px;
    background: #ffffff;
    box-shadow: 0px -3px 6px 1px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    z-index: 99;
  }
}
</style>